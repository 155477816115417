<template>
  <v-app id="cigre">
    <v-content>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Članstvo u HRO CIGRÉ</v-toolbar-title>
              </v-toolbar>
              <v-subheader>
                Molimo odaberite jednu od ponuđenih opcija:
              </v-subheader>
              <v-card-text>
                <v-form ref="form">
                  <div class="optback">
                    <v-checkbox
                      v-model="formdata.pprivate"
                      label="Članarinu želim plaćati privatno putem dostavljene uplatnice"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="formdata.pcompany"
                      label="Članarinu će platiti poduzeće u kojem sam zaposlen. Želim da HRO CIGRÉ dostavi račun poduzeću"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="formdata.pno"
                      label="Ne želim biti član HRO CIGRÉ"
                    ></v-checkbox>
                  </div>
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-show="showagree"
                    v-model="agree"
                    :rules="[
                      v =>
                        !!v ||
                        'Prije slanja vašeg odabira, morate potvrditi navedenu izjavu!'
                    ]"
                    label="Slažem se da HRO CIGRÉ koristi moje osobne podatke u svrhu plaćanja članarine, slanja obavijesti i ostalih aktivnosti vezanih uz članstvo"
                    required
                  ></v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="disabled" color="secondary" @click="send"
                  >Potvrdi moj odabir</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-alert v-model="error" dismissible type="error">
              Nažalost, došlo je do pogreške. Molimo obratite se e-mailom na
              info@hro-cigre.hr.
            </v-alert>
            <v-alert v-model="success" dismissible type="success">
              Zahvaljujemo se! Vaš odabir je zaprimljen!
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    formdata: {
      pprivate: false,
      pcompany: false,
      pno: false,
      uuid: ""
    },
    agree: false,
    error: false,
    success: false,
    disabled: true,
    showagree: false,
    nowatch: false
  }),

  methods: {
    send() {
      var me = this;
      me.error = false;
      me.success = false;
      this.formdata.uuid = this.getUrlVars()["uref"];
      if (
        ((this.formdata.pprivate || this.formdata.pcompany) && this.agree) ||
        this.formdata.pno
      ) {
        this.$http
          .put("membership/membershipquestion.php", this.formdata)
          .then(function(response) {
            if (response.data.success) {
              // successfull
              me.success = true;
              me.error = false;
            } else {
              // failed
              me.success = false;
              me.error = true;
            }
          })
          .catch(function() {
            // failed
            me.success = false;
            me.error = true;
          });
      } else {
        this.$refs.form.validate();
      }
    },
    getUrlVars() {
      var vars = {};
      var parts = window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function(m, key, value) {
          vars[key] = value;
        }
      );
      return vars;
    }
  },
  watch: {
    "formdata.pprivate": function(n) {
      if (n) {
        this.nowatch = true;
        this.formdata.pcompany = false;
        this.formdata.pno = false;
        this.disabled = false;
        this.showagree = true;
      } else if (!this.nowatch) this.disabled = true;
      else this.nowatch = false;
    },
    "formdata.pcompany": function(n) {
      if (n) {
        this.nowatch = true;
        this.formdata.pprivate = false;
        this.formdata.pno = false;
        this.disabled = false;
        this.showagree = true;
      } else if (!this.nowatch) this.disabled = true;
      else this.nowatch = false;
    },
    "formdata.pno": function(n) {
      if (n) {
        this.nowatch = true;
        this.formdata.pprivate = false;
        this.formdata.pcompany = false;
        this.disabled = false;
        this.showagree = false;
      } else if (!this.nowatch) this.disabled = true;
      else this.nowatch = false;
    }
  }
};
</script>
<style scoped>
.optback {
  background-color: #eeeeee;
  padding: 20px 20px 20px 20px;
}
</style>
